import React from 'react' 
import FAQ from "../images/svgLogos/faq.svg"
import report from "../images/svgLogos/report.svg"
import approve from "../images/svgLogos/approve.svg"
import monitor_img from "../images/rankmsme.jpeg"
import playstore from "../images/apps/playstore.png"
import appstore from "../images/apps/appstore.png" 
import "../CSS/home.css"
import { Heading } from '@chakra-ui/react'
import { Footer } from './Footer' 
import { Link } from 'react-router-dom'
import home_banner from '../../src/images/home-banner.svg'
import banner from '../../src/images/banner.svg'
import Banner from './Banner'
import increaseCreditScore from '../images/Increase-credit-score.jpeg';
import creditScore from '../images/credit-score.jpg';

export const Home = () => {
  return (
    <div>
        <Banner/>
        <div className='slider'>
            <div className='slider-content' >
                <h1>Las financieras verifican en MyCreditScore antes de aprobar tu préstamo*</h1>
                <Link to='/login'><button>Obtén MyCreditScore en la Apple App Store</button></Link>
                <p>¿Ya tienes una cuenta de MyCreditScore? <a href="#">Iniciar sesión</a></p>
                <p>No te preocupes. Revisar tu propio puntaje de crédito.</p>
            </div>
            <div className='lapi-img' >
                <img style={{width: '600px'}} src={home_banner} alt="not found" />
            </div>
        </div>
        <div className='text-line' >
            <p>Aprende. Planifica. Protege. Todo en un solo lugar. Aquí te mostramos cómo.</p>
        </div>
        <div className='cibil-credit-info' >
            <div className='about-credits' >
                <div className='credit-text' >
                    <hr />
                    <h2>Monitoreo de crédito 24x7 con alertas de MyCreditScore</h2>
                    <p>Protege contra el robo de identidad con notificaciones por correo electrónico y SMS.</p>
                </div>
                <div className='credit-text' >
                    <hr />
                    <h2>Acceso ilimitado a tu panel de MyCreditScore</h2>
                    <p>Recibe actualizaciones regulares de en MyCreditScore y Reporte.</p>
                </div>
                <div className='credit-text' >
                    <hr />
                    <h2>Toma decisiones crediticias acertadas con el Simulador de Puntaje</h2>
                    <p>Descubre cómo tus acciones de crédito pueden afectar en MyCreditScore.</p>
                </div>
                <div className='credit-text' >
                    <hr />
                    <h2>Obtén ofertas de préstamos personalizadas</h2>
                    <p>Ofertas de préstamos a medida basadas en  MyCreditScore más reciente.</p>
                </div>
            </div>
            <Heading fontSize='20px' as='p'  size='md' >Todo lo que necesitas para estar listo para un préstamo, siempre.</Heading>
            <button><Link to='/login'>OBTÉN  MyCreditScore</Link></button>
        </div>
        <div className='help-center'>
            <div className='help-heading' >
                <h2>Centro de Ayuda</h2>
                <p>Aprende sobre MyCreditScore y cómo podemos ayudarte.</p>
            </div>
            <div className='help-card'>
                <div className='card' >
                    <img src={FAQ} alt="" />
                    <h3>Preguntas Frecuentes</h3>
                    <p>Obtén respuestas rápidas a tus preguntas más comunes.</p>
                    <h2>Saber más</h2>
                </div>
                <div className='card' >
                    <img src={report} alt="" />
                    <h3>Por qué tu MyCreditScore y Reporte</h3>
                    <p>Aprende por qué es importante no solo para ti, sino también para las financieras.</p>
                    <h2>Saber más</h2>
                </div>
                <div className='card' >
                    <img src={approve} alt="" />
                    <h3>Proceso de aprobación de préstamos</h3>
                    <p>Entiende cómo las financieras evalúan tu solicitud de préstamo.</p>
                    <h2>Saber más</h2>
                </div>
            </div>
            <p>Somos la empresa líder en información crediticia en Chile, con una de las mayores colecciones de información de consumidores.</p>
        </div>
        <div className='monitor' >
            <div className='monitor-img' >
                <img src={monitor_img} alt="not found" />
            </div>
            <div className='monitor-text'>
                <h2>Monitorea la salud crediticia de tu empresa con MyCreditScore Rank.</h2>
                <p>Acceso fácil: consulta tu reporte en línea en cualquier momento y lugar.</p>
                <h3>Las empresas que obtuvieron préstamos tendrán MyCreditScore.</h3>
                <button><Link to='/login'>Comenzar</Link></button>
            </div>
        </div>
        <div className='app'>
            <div className='app-link' style={{display: 'flex'}}>
                <img style={{
                    width:"40%",
                    borderRadius:"5%"
                }} src={increaseCreditScore} alt="er" />
                <img style={{
                    width:"40%",
                    borderRadius:"5%"
                }} src={creditScore} alt="er" />
            </div>
                
        </div>
        <div className='app'>
            <div className='app-link'>
                <h2>Monitorea tu MyCreditScore sobre la marcha con la aplicación de MyCreditScore.</h2>
                <img src={playstore} alt="not found" />
                <img src={appstore} alt="not found" />
            </div>
        </div>
        <div className="pic">
            <img style={{width: '620px',display: 'block', margin: 'auto'}} src={banner} alt="not found" />
        </div>
        <div className='bottom-text'>
            <h2 style={{marginTop: '2rem'}}>Obtén tu MyCreditScore y Reporte hoy mismo.</h2>
            <button>Comenzar Ahora</button>
        </div>
        <hr style={{backgroundColor:'#0094e9c2',height:"2px"}} />
        <Footer/>
    </div>
  )
}
