import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import login_banner from "../images/login-banner.svg";
import { Spinner } from "@chakra-ui/react";
import axios from "axios";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);
  const toast = useToast();
  const isAuth = useSelector((store) => store.authReducer.isAuth);
  const user = useSelector((store) => store.authReducer.User);
  

  // Validación básica de campos
  const validateForm = () => {
    if (!email || !password) {
      toast({
        title: "Campos vacíos",
        description: "Por favor, ingrese email y contraseña.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    if (!validateForm()) return;

    setSpinner(true);

    try {
      // Realizar la petición al API de login ppp
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/Prod/mycreditscore/api/login`,
        { username: email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      const { token, rol, cliente } = response.data;

      if (token && token.length > 0) {
        const userdata = JSON.stringify({ cliente, rol });
        localStorage.setItem("token", token);
        localStorage.setItem("user", userdata);
        window.location.href = `${process.env.REACT_APP_WEB_URL}/login?token=${encodeURIComponent(token)}&user=${userdata}`;
      } else {
        toast({
          title: "Acceso denegado",
          description: "No tienes permisos suficientes para acceder.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error en el login:", error);
      toast({
        title: "Error de inicio de sesión",
        description:
          error.response?.data?.message || "Usuario o contraseña incorrectos",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setEmail("");
      setPassword("");
    } finally {
      setSpinner(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "2rem",
        marginTop: "3rem",
        flexWrap: "wrap",
      }}
    >
      <div>
        <img
          className="login-img"
          style={{ width: "350px", margin: "2rem", opacity: "0.9" }}
          src={login_banner}
          alt="Login Banner"
        />
      </div>
      <DIV>
        {isAuth ? (
          <h3>Inicio de sesión exitoso</h3>
        ) : (
          <h2>Página de inicio de sesión</h2>
        )}
        {spinner && (
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        )}
        {isAuth && <h3> Usuario: {user.name}</h3>}
        <input
          type="email"
          value={email}
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          value={password}
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin} disabled={spinner}>
          {spinner ? "Cargando..." : "Iniciar sesión"}
        </button>
        <Link to="/sign-up">
          <p>
            No tienes ninguna cuenta{" "}
            <span style={{ color: "#24ADF3" }}>crear nuevo</span>
          </p>
        </Link>
      </DIV>
    </div>
  );
};
export default Login;

const DIV = styled.div`
  width: 400px;
  padding: 20px;
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  margin-top: 2rem;
  gap: 15px;
  border: 1px solid #ccc;
  margin-bottom: 3rem;
  align-items: center;
  height: fit-content;
  padding: 2rem;

  input {
    width: 80%;
    padding: 8px;
    color: #333;
    font-size: larger;
    border-radius: 6px;
    border: ${({ err }) =>
      err === "true" ? "2px solid red" : "1px solid #ccc"};
  }
  button {
    width: 120px;
    padding: 7px;
    font-size: medium;
    background-color: #32a9ed;
    color: #fff;
    border-radius: 6px;
    font-weight: 300;
    font-size: 17px;
    border: none;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
  h3 {
    color: #00ad00;
    font-weight: 500;
    line-height: 30px;
    font-size: 24px;
  }
`;
